<template>
  <div>
    <b-card>
      <b-card-text> 
        Use fun names for your discount code and give it a brief but meaningful description.<br />
        Note: Although the code is uppercase, it will be case insensitive when used.
      </b-card-text>
    </b-card>
    <b-form @submit="saveDiscountCode" inline>
      <label class="sr-only" for="dc-form-input-code">Code</label>
      <b-input
        id="dc-form-input-code"
        v-model="discount.code"
        class="mb-2 mr-sm-2 mb-sm-0"
        placeholder="e.g. FIRSTTIME"
        maxlength="14"
        v-uppercasenospace
        required
      ></b-input>
      <label class="sr-only" for="dc-form-input-amount">Amount</label>
      <b-input
        id="dc-form-input-amount"
        v-model="discount.amount"
        class="mb-2 mr-sm-2 mb-sm-0"
        placeholder="e.g. 15"
        type="number"
        min="1"
        max="999"
        number
        required
      ></b-input>
      <label class="sr-only" for="dc-form-input-type">Type</label>
      <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="discount.type" :options="typeOptions" required></b-form-select>
      <label class="sr-only" for="dc-form-input-code">Description</label>
      <b-input
        id="dc-form-input-description"
        v-model="discount.description"
        class="mb-2 mr-sm-2 mb-sm-0"
        placeholder="e.g. first-timers discount"
        maxlength="30"
        required
      ></b-input>
      <label for="dc-form-input-code">Expires:&nbsp;</label>
      <b-input
        id="dc-form-input-dateExpires"
        type="date"
        v-model="discount.dateExpires"
        class="mb-2 mr-sm-2 mb-sm-0"
        required
      ></b-input>
      <b-btn
        id="dc-form-input-submit"
        class="mb-2 mr-2 mb-sm-0"
        variant="success"
        type="submit"
      >Save</b-btn>
      <b-btn
        v-if="discount.id"
        id="dc-form-input-archive"
        class="mb-2 mr-sm-2 mb-sm-0"
        variant="warning"
        type="button"
        @click="archiveDiscountCode"
      >Archive</b-btn>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data () {
    return {
      typeOptions: [
        { value: 'percent', text: 'Percent' },
        { value: 'dollar', text: 'Dollars' }
      ],
      discount: {
        id: null,
        code: null,
        amount: null,
        type: 'percent',
        description: '',
        dateExpires: null
      }
    }
  },
  methods: {
    ...mapActions({
      getDiscountCode: 'getDiscountCode',
      postDiscountCode: 'postDiscountCode',
      putDiscountCode: 'putDiscountCode'
    }),
    saveDiscountCode (e) {
      e.preventDefault()
      if (this.discount.id) {
        this.putDiscountCode(this.discount).then(() => {
          this.showSuccess()
        })
      } else {
        this.discount.business = this.currentBusiness['@id']
        this.postDiscountCode(this.discount).then(resp => {
          this.showSuccess()
          this.$router.push({ name: 'discountCodeForm', params: { did: resp.id } })
        })
      }
    },
    archiveDiscountCode () {
      if (confirm('Archive this discount code?')) {
        this.putDiscountCode({
          id: this.discount.id,
          active: false
        })
      }
    },
    doGetDiscountCode () {
      this.getDiscountCode({
        id: this.$route.params.did
      }).then(resp => {
        resp.dateExpires = this.$moment(resp.dateExpires).format('YYYY-MM-DD')
        this.discount = resp
      })
    }
  },
  directives: {
    uppercasenospace: {
      bind(el, _, vnode) {
        el.addEventListener('keyup', e => {
          e.target.value = e.target.value.toUpperCase().replace(/\s/, '')
          vnode.componentInstance.$emit('input', e.target.value.toUpperCase().replace(/\s/, ''))
        })
      }
    }
  },
  watch: {
    '$route' (to) {
      this.doGetDiscountCode()
    }
  },
  created () {
    if (this.$route.params.did) {
      this.doGetDiscountCode()
    }
  }
}
</script>